<template>
  <div
    id="book"
    class="relative mx-auto max-w-[var(--breakpoint-xl)] scroll-mt-48 px-4 sm:px-8"
  >
    <section class="mt-16 w-full">
      <LazyUCard
        :ui="{
          root: 'relative isolate bg-neutral-950/60 backdrop-blur-lg ring-yellow-400/50 overflow-hidden shadow-lg',
        }"
      >
        <div
          class="absolute inset-0 h-full w-full animate-pulse opacity-20"
          style="animation-duration: 5s"
        >
          <LazyBrandTopography />
        </div>
        <div
          class="absolute inset-0 z-[1] h-full w-full bg-gradient-to-b from-transparent via-neutral-950/50 to-neutral-950"
        />
        <LazyUBadge
          class="absolute top-3 right-3 z-[3] rounded-full bg-neutral-800 p-1.5 backdrop-blur-lg"
        >
          <UIcon name="i-lucide-sparkles" class="size-5 text-yellow-400" />
        </LazyUBadge>
        <div class="relative z-[2]">
          <div class="text-base font-bold text-yellow-400 uppercase">
            Book Now
          </div>
          <h2 class="mt-0.5 text-3xl font-extrabold text-white">
            Discover Your Next Adventure
          </h2>
          <div
            class="mt-6 items-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4"
          >
            <LazyUSelectMenu
              v-model="locationValue"
              placeholder="Select A Location:"
              variant="soft"
              :items="sortedLocations"
              class="w-full"
              size="xl"
              :ui="{
                placeholder: 'font-bold uppercase',
                value: 'font-bold text-yellow-400 uppercase',
                item: 'font-bold uppercase',
              }"
            />
            <LazyUSelectMenu
              v-model="activityValue"
              placeholder="Select An Activity:"
              variant="soft"
              :items="sortedActivities"
              class="w-full"
              size="xl"
              :disabled="sortedActivities.length === 0"
              :ui="{
                placeholder: 'font-bold uppercase',
                value: 'font-bold text-yellow-400 uppercase',
                item: 'font-bold uppercase',
              }"
            />
            <LazyUButton
              title="Go To Activity"
              variant="soft"
              size="xl"
              color="neutral"
              :disabled="
                locationValue.label === 'Select A Location:' ||
                activityValue.label === 'Select An Activity:'
              "
              icon="i-lucide-arrow-right-circle"
              class="w-full cursor-pointer justify-center text-center text-yellow-400 sm:w-fit"
              @click="bookNow()"
            />
          </div>
        </div>
      </LazyUCard>
    </section>
  </div>
</template>

<script setup lang="ts">
interface Location {
  name: string;
  content: {
    town: string;
  };
  activityCount: number;
}

interface Product {
  price: number;
}

// Define StoryblokActivity interface for the API response
interface StoryblokActivity {
  name: string;
  slug: string;
  content: {
    activity_type: string;
    locations: Location[];
    products: Product[];
  };
}

interface SelectOption {
  label: string;
  value: string;
}

const { fetchActivities } = useStoryblokClient();
const { slugify } = useSlugify();

const locationValue = ref<SelectOption>({
  label: 'Select A Location:',
  value: '',
});
const activityValue = ref<SelectOption>({
  label: 'Select An Activity:',
  value: '',
});
const activities = ref<StoryblokActivity[]>([]);
const locations = ref<Location[]>([]);
const sortedActivities = ref<SelectOption[]>([]);

// Load activities and set unique locations with activity count
const loadActivities = async (): Promise<void> => {
  try {
    const [stories] = (await Promise.all([fetchActivities()])) as [
      StoryblokActivity[],
    ];
    activities.value = stories.filter(
      (story) => story.content.activity_type !== 'corporates'
    );

    // Extract unique locations from activities by using a Map to avoid duplicates
    const locationMap = new Map<string, Location>();

    activities.value.forEach((activity) => {
      activity.content.locations.forEach((loc: Location) => {
        if (locationMap.has(loc.name)) {
          const existing = locationMap.get(loc.name);
          if (existing) {
            existing.activityCount++;
          }
        } else {
          locationMap.set(loc.name, { ...loc, activityCount: 1 });
        }
      });
    });

    locations.value = Array.from(locationMap.values());
  } catch (error) {
    console.error('Error loading activities:', error);
  }
};

// Computed property to create a sorted map of locations by name
const sortedLocations = computed<SelectOption[]>(() => {
  return [...locations.value]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((location) => ({
      label: `${location.name} (${location.content.town})`,
      value: location.name,
    }));
});

// Watcher for locationValue to update sortedActivities
watch(locationValue, (newLocation: SelectOption) => {
  sortedActivities.value = activities.value
    .filter((activity) =>
      activity.content.locations.some(
        (location) => location.name === newLocation.value
      )
    )
    .sort((a, b) => {
      const priceA = a.content.products[0]?.price ?? 0;
      const priceB = b.content.products[0]?.price ?? 0;

      if (priceB !== priceA) {
        return priceB - priceA;
      }
      return a.name.localeCompare(b.name);
    })
    .map((activity) => ({
      label: activity.name,
      value: activity.slug,
    }));
});

await loadActivities();

const bookNow = (): void => {
  if (locationValue.value.label === 'Select A Location:') {
    alert('Please select a location');
  } else if (activityValue.value.label === 'Select An Activity:') {
    alert('Please select an activity');
  } else {
    navigateTo(
      `/activities/${
        activityValue.value.value
      }?location=${slugify(locationValue.value.value)}`
    );
  }
};
</script>
